import helper from '@/api/helper';

const getInverterTime = (_rawData) => new Promise((resolve) => {
  if (_rawData.length === 0) {
    return;
  }

  const minuteTimeList = {};

  Object.keys(_rawData).forEach((key) => {
    let hour;
    let mnt;
    const h = _rawData[key].hour;
    const mn = _rawData[key].minute;
    // eslint-disable-next-line no-unused-expressions
    h < 10 ? (hour = `0${h}`) : (hour = `${h}`);
    // eslint-disable-next-line no-unused-expressions
    mn < 10 ? (mnt = `0${mn}`) : (mnt = `${mn}`);

    const selectime = `${hour}:${mnt}`;
    minuteTimeList[selectime] = _rawData[key];
  });

  resolve(minuteTimeList);
});

const getInverterTimeAcPowers = (_rawData, measureLists, inverterDcPower) => new Promise((
  resolve,
) => {

  if (_rawData === undefined) {
    return;
  }

  const colors = ['green', 'grey'];

  const rawData = _rawData.InverterList.sort((a, b) => {
    if (a.INVERTER < b.INVERTER) return -1;
    if (a.INVERTER > b.INVERTER) return 1;
    return 0;
  });

  const tempDataType = [];
  const seriesList = [];
  const measures = [];

  measureLists.forEach((measure) => {
    measures.push(measure.value);
    const data = [];

    let inverterkey = 'INVERTERNAME';
    Object.keys(rawData).forEach((key) => {
      if (helper.isObjectKeysExist(rawData[key], 'INVERTERNAME')) {
        inverterkey = 'INVERTERNAME';
      } else {
        inverterkey = 'INVERTER';
      }

      if (measure.value === 'INVERTERNAME') {
        const dcpower = parseFloat(inverterDcPower[rawData[key][inverterkey]]) -
          rawData[key].ACPower;
        tempDataType.push(rawData[key][inverterkey]);

        data.push(dcpower.toFixed(2));
      } else {
        data.push(parseFloat(rawData[key][measure.value]).toFixed(2));
      }
    });

    seriesList.push({
      name: measure.value,
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data,
    });
  });

  // eslint-disable-next-line no-use-before-define
  const option = lineOpt(colors, tempDataType, measures, seriesList);
  resolve(option);
});

const lineOpt = (colors, tempDataType, measureList, series) => ({
  color: colors,
  tooltip: {
    trigger: 'axis',
    axisPointer: { // Use axis to trigger tooltip
      type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
    },
    formatter(params) {
      let text = `${params[0].axisValueLabel}<br>`;
      const inveterVal = parseFloat(params[0].value) + parseFloat(params[1].value);

      text += `${params[0].seriesName}：${parseFloat(params[0].value).toFixed(2)} kWh <br>`;
      text += `INVERTER ：${parseFloat(inveterVal).toFixed(2)} kWh <br>`;
      return text;
    },
  },
  legend: {
    data: measureList,
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    data: tempDataType,
  },
  yAxis: {
    type: 'value',

  },
  series,
});

export default {
  getInverterTime,
  getInverterTimeAcPowers,
};
