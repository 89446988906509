<template>
  <div>
    <v-col
      cols="12"
      class="text-center mt-8"
    >
      <v-card>
        <v-row class="px-2 py-2">
          <v-spacer />

          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
            xl="2"
            class="py-0 my-0 mb-2"
          >
            <v-select
              v-model="santralModel"
              :items="santralList"
              label="Santral"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="2"
            xl="2"
            class="py-0 my-0 mb-2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="startDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Kapat
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(startDate)"
                >
                  Seç
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="2"
            xl="2"
            class="py-0 my-0 mb-2"
          >
            <v-slider
              v-model="hoursModel"
              :thumb-size="30"
              thumb-label="always"
              outlined
              dense
            >
              <template v-slot:thumb-label="{}">
                {{ selectHour }}
              </template>
            </v-slider>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="2"
            lg="2"
            xl="1"
            class="py-0 my-0 mb-2"
          >
            <v-btn
              color="primary"
              @click="getDailyMinuteEnergy"
            >
              Filtrele
            </v-btn>
          </v-col>
        </v-row>

        <v-col
          cols="12"
        >
          <div
            id="invert_Analysis"
            style="
              height: 350px;
              min-width: 100%;
              width: 100%;
              padding: 1px;
              overflow-x: auto;
            "
          />
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      santralModel: null,
      santralList: [], // Populate with actual data
      menu: false,
      startDate: null,
      hoursModel: 12,
    };
  },
  computed: {
    selectHour() {
      return `${this.hoursModel} saat`;
    },
  },
  methods: {
    getDailyMinuteEnergy() {
      // Your method implementation
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .px-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .py-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .mb-2 {
    margin-bottom: 16px !important;
  }
}
</style>

<script>
import * as echarts from 'echarts';
import * as Timeofinverter from '@/api/Inverter/Timeofinverter';
import moment from 'moment';
import i18n from '@/locale';
import helper from '@/api/helper';
import { getTimeofinverter } from '@/api/Request/inverter';

export default {
  name: 'Dashboard',
  components: {},
  data: () => ({
    startDate: moment().format('YYYY-MM-DD'),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format('YYYY'),

    menu: false,
    modal: false,
    menu2: false,
    livehourdata: {},
    inverterList: {},
    measureList: [],
    hoursModel: 50,
    hourSelected: [],
    componentKey: 1,
    selectHour: '',
    inverterPowerData: {},
    santralModel: '',
    santralInfo: [],
    santralRawData: [],
    windowsWidth: `${document.documentElement.clientWidth - 100}px`,
  }),
  computed: {
    santralList() {
      const { santralRawData } = this.$store.getters;

      if (santralRawData === undefined) return;
      if (santralRawData.length === 0) return;

      const santralList = [];
      const santralRawList = {};
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      santralRawData.forEach((item) => {
        santralRawList[item.santralCode] = item;
        santralList.push({
          text: item.santral,
          value: item.santralCode,
        });
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.santralRawData = santralRawList;
      // eslint-disable-next-line consistent-return
      return santralList;
    },
  },
  watch: {
    hoursModel() {
      this.getInverterTimeAcPowers();
    },
    santralList() {
      this.santralModel = this.santralList[0].value;
      if (this.santralModel !== undefined) {
        this.getDailyMinuteEnergy();
      }
    },
  },
  created() {
    this.setIntervalInverter();
  },
  mounted() {
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    setIntervalInverter() {
      const { selectedFarm } = this.$store.getters;

      if (selectedFarm !== undefined && Object.keys(selectedFarm).length > 0) {
        let stepTime = helper.getFarmStep(selectedFarm.prefix);
        const timeList = [];
        stepTime = 10;
        for (let h = 5; h < 22; h += 1) {
          for (let mn = 0; mn < 60; mn += stepTime) {
            let hour;
            let mnt;

            // eslint-disable-next-line no-unused-expressions
            h < 10 ? (hour = `0${h}`) : (hour = `${h}`);
            // eslint-disable-next-line no-unused-expressions
            mn < 10 ? (mnt = `0${mn}`) : (mnt = `${mn}`);
            if (timeList.length < 201) {
              timeList.push(`${hour}:${mnt}`);
            }
          }
        }

        this.hoursModel = 50;
        this.hourSelected = timeList;
        this.selectHour = timeList[timeList.length / 2];
      }
    },
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate).format('YYYY-MM-DD');
      let santral = this.santralModel;

      this.santralList.forEach((item) => {
        if (item.value === this.santralModel) {
          santral = item.text;
        }
      });

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          santral,
          column: [
            'ACPower',
            'inverter',
            'INVERTERNAME',
            'INVERTER',
            'santral',
            'date',
            'datetimestamp',
            'PerformanceRatio',
          ],
        },
      };
      getTimeofinverter(params).then((res) => {
        if (helper.isObjectKeysExist(res.data, 'data')) {
          const tempData = Timeofinverter.default.getInverterTime(
            res.data.data,
          );
          tempData.then((data) => {
            this.componentKey += 1;
            this.inverterPowerData = data;
            this.getInverterTimeAcPowers();
          });
        }
      });
    },

    getInverterTimeAcPowers() {
      const inverterDcPower = {};
      const { inverterData } = this.$store.getters;

      Object.keys(inverterData).forEach((item) => {
        if (helper.isObjectKeysExist(inverterData[item], 'INVERTERNAME')) {
          const val = inverterData[item].installedDcPower;
          const key = inverterData[item].INVERTERNAME;
          inverterDcPower[key] = val;
        } else {
          const val = inverterData[item].installedDcPower;
          const key = inverterData[item].INVERTER;
          inverterDcPower[key] = val;
        }
      });

      const res = this.inverterPowerData;
      this.measureList = [
        { value: 'ACPower', text: i18n.t('ACPower'), unit: 'kWh' },
        { value: 'INVERTERNAME', text: 'Inverter DC Güçü', unit: '%' },
      ];

      this.selectHour = this.hourSelected[this.hoursModel];
      const optionData = Timeofinverter.default.getInverterTimeAcPowers(
        res[this.selectHour],
        this.measureList,
        inverterDcPower,
      );
      const chartDom = document.getElementById('invert_Analysis');
      const myChart = echarts.init(chartDom);
      optionData.then((result) => {
        // eslint-disable-next-line no-unused-expressions
        myChart.setOption(result, true);
        // this.livehourdata = result;
        this.componentKey += 1;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .px-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .py-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .mb-2 {
    margin-bottom: 16px !important;
  }
}
</style>
